import {
  Charge,
  Coupon,
  Maybe,
  PackageType,
  ProductReturnRequirement,
  ShippingCharge,
  ShippingOption,
  ShippingPreferences,
} from 'API';
import { find } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { toast } from 'react-hot-toast';
import { InvoiceAccount } from 'shared/api/invoice.api';
import { DISCOUNT_TYPE_LABELS } from 'shared/constants/invoice-data.constants';
import { DO_NOT_SHIP_PREFERENCE_ENUM, ShippingCarrier, ShippingService } from 'shared/enums';
import { getDefaultCharge, getDiscountCouponItem } from 'shared/helpers/invoice/invoice.helper';
import {
  convertDeductionToItems,
  getOutboundDiscountPercentage,
} from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrder } from 'shared/models';
import { getOutboundApplicableCase, getSpiltBundleCaseInfo } from 'shared/utils';
import { DisplayDiscountItemType } from 'types/common';

/**
 * Represents the action types for the invoicing detail module.
 */
type ReducerAction =
  | {
      type: 'setAccount';
      payload: InvoiceAccount | null;
    }
  | {
      type: 'setShipping';
      payload: CreatedOrder['shipping'];
    }
  | {
      type: 'setShippingOptions';
      payload: ShippingOption[];
    }
  | {
      type: 'setOnSubmitAlertCount';
      payload: number;
    }
  | {
      type: 'setCases';
      payload: CreatedOrder[];
    }
  | {
      type: 'newCase';
      payload: CreatedOrder;
    }
  | {
      type: 'updateCase';
      payload: Partial<CreatedOrder> & Pick<CreatedOrder, 'orderNumber'>;
    }
  | {
      type: 'deleteCase';
      payload: string;
    }
  | {
      type: 'setInvoiceShippingInput';
      payload: Partial<InvoicingDetailState['invoiceShippingInput']>;
    }
  | {
      type: 'setInvoiceAddDiscountInput';
      payload: Partial<InvoicingDetailState['invoiceAddDiscountInput']>;
    }
  | {
      type: 'setProductReturnRequirements';
      payload: InvoicingDetailState['productReturnRequirements'];
    }
  | {
      type: 'setCodAmount';
      payload: number;
    };

/**
 * Represents the state for the invoicing detail module.
 */
export interface InvoicingDetailState {
  account: InvoiceAccount | null;
  shipping: CreatedOrder['shipping'];
  shippingOptions: ShippingOption[];
  invoiceCases: CreatedOrder[];
  invoiceShippingInput: {
    trackingNumber: string;
    showTrackingNumberInput: boolean;
    inValidTrackingNumber: boolean;
    trackingNumberBundledOrder?: {
      orderNumber: string;
      shipmentId: string;
      rateId: string;
      shippingCharges: ShippingCharge[];
      isChargeEligible: boolean;
    };
    packageType: PackageType | null;
    carrier: string;
    shippingService: string;
    isDoctorPickup: boolean;
    isSignatureRequired: boolean;
    isSaturdayDelivery: boolean;
    doNotShip: ShippingPreferences['doNotShip'];
    doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM;
    isSignatureRequiredPreference: boolean | null;
    carrierPreference: string;
    servicePreference: string;
    totalAmount?: Maybe<number>;
  };
  invoiceAddDiscountInput: {
    coupons: DisplayDiscountItemType[];
    specialDiscounts: DisplayDiscountItemType[];
  };
  onSubmitAlertCount: number;
  spiltBundleCaseInfo: ReturnType<typeof getSpiltBundleCaseInfo>;
  productReturnRequirements: ProductReturnRequirement[];
  outboundApplicableCase: CreatedOrder | undefined;
  codAmount: number;
}

/**
 * Represents the action methods for the invoicing detail module.
 */
export interface InvoicingDetailAction {
  setSpiltBundleCaseInfo: React.Dispatch<React.SetStateAction<InvoicingDetailState['spiltBundleCaseInfo']>>;
  dispatch: React.Dispatch<ReducerAction>;
}

/**
 * Represents the context for the invoicing detail module.
 */
export type InvoicingDetailContext = InvoicingDetailState & InvoicingDetailAction;

/**
 * The initial state for the invoicing detail module.
 */
const initialState: InvoicingDetailState = {
  account: null,
  shipping: null,
  shippingOptions: [],
  invoiceCases: [],
  onSubmitAlertCount: 0,
  invoiceShippingInput: {
    trackingNumber: '',
    showTrackingNumberInput: false,
    inValidTrackingNumber: false,
    packageType: PackageType.Box,
    carrier: '',
    isDoctorPickup: false,
    shippingService: '',
    isSignatureRequired: false,
    isSaturdayDelivery: false,
    doNotShip: [],
    doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM.NONE,
    isSignatureRequiredPreference: null,
    carrierPreference: '',
    servicePreference: '',
    totalAmount: 0,
  },
  invoiceAddDiscountInput: {
    coupons: [],
    specialDiscounts: [],
  },
  spiltBundleCaseInfo: getSpiltBundleCaseInfo(null),
  productReturnRequirements: [],
  outboundApplicableCase: undefined,
  codAmount: 0,
};

/**
 * The context for the invoicing detail module.
 */
const InvoicingDetailModuleContext = React.createContext<InvoicingDetailContext | null>(null);

/**
 * The reducer function for the invoicing detail module.
 */
const reducer = (state: InvoicingDetailState, action: ReducerAction): InvoicingDetailState => {
  switch (action.type) {
    case 'setAccount':
      return { ...state, account: action.payload };
    case 'setShipping':
      return { ...state, shipping: action.payload };
    case 'setShippingOptions':
      return { ...state, shippingOptions: action.payload };
    case 'setOnSubmitAlertCount':
      return { ...state, onSubmitAlertCount: action.payload };
    case 'setCases':
      return { ...state, invoiceCases: action.payload };
    case 'newCase': {
      const existingCase = state.invoiceCases.find(c => c.orderNumber === action.payload.orderNumber);
      if (existingCase) return state;
      return { ...state, invoiceCases: [...state.invoiceCases, action.payload] };
    }
    case 'updateCase':
      return {
        ...state,
        invoiceCases: state.invoiceCases.map(c => {
          if (c.orderNumber === action.payload.orderNumber) {
            return {
              ...c,
              ...action.payload,
            } as CreatedOrder;
          }
          return c;
        }),
      };
    case 'deleteCase':
      return { ...state, invoiceCases: state.invoiceCases.filter(c => c.orderNumber !== action.payload) };
    case 'setInvoiceShippingInput':
      return { ...state, invoiceShippingInput: { ...state.invoiceShippingInput, ...action.payload } };
    case 'setInvoiceAddDiscountInput': {
      return {
        ...state,
        invoiceAddDiscountInput: {
          ...state.invoiceAddDiscountInput,
          ...action.payload,
        },
      };
    }
    case 'setProductReturnRequirements':
      return { ...state, productReturnRequirements: action.payload };
    case 'setCodAmount':
      return { ...state, codAmount: action.payload };
    default:
      return state;
  }
};

/**
 * The props for the InvoicingDetailModuleProvider component.
 */
interface IProps {
  initialState?: Partial<InvoicingDetailState>;
}

/**
 * The provider component for the invoicing detail module.
 */
export const InvoicingDetailModuleProvider: React.FC<IProps> = ({ children, initialState: overrideInitialState }) => {
  const initObject: InvoicingDetailState = { ...initialState, ...overrideInitialState };
  const [state, dispatch] = useReducer(reducer, initObject);
  const [spiltBundleCaseInfo, setSpiltBundleCaseInfo] = useState(getSpiltBundleCaseInfo(null));

  /**
   * Set the split bundle case info, if any.
   */
  useEffect(() => {
    // If any of the cases are split bundles, get the split bundle case info.
    const invoiceCase = state.invoiceCases.find(invoiceCase => getSpiltBundleCaseInfo(invoiceCase).isBundle);
    const spiltBundleCase = getSpiltBundleCaseInfo(invoiceCase);
    setSpiltBundleCaseInfo(spiltBundleCase);
  }, [state.invoiceCases]);

  return (
    <InvoicingDetailModuleContext.Provider value={{ ...state, spiltBundleCaseInfo, setSpiltBundleCaseInfo, dispatch }}>
      {children}
    </InvoicingDetailModuleContext.Provider>
  );
};

/**
 * Custom hook to access the invoicing detail context.
 */
export const useInvoicingDetail = () => {
  const context = useContext(InvoicingDetailModuleContext);

  if (!context) {
    throw new Error('useInvoicingDetail must be used within a InvoicingDetailModuleProvider');
  }

  const {
    account,
    shipping,
    shippingOptions,
    onSubmitAlertCount,
    invoiceCases,
    invoiceShippingInput,
    invoiceAddDiscountInput,
    productReturnRequirements,
    dispatch,
    codAmount,
  } = context;

  const setAccount = useCallback(
    (account: InvoicingDetailState['account']) => {
      dispatch({ type: 'setAccount', payload: account });
    },
    [dispatch]
  );

  const setShipping = useCallback(
    (shipping: InvoicingDetailState['shipping']) => {
      dispatch({ type: 'setShipping', payload: shipping });
    },
    [dispatch]
  );

  const setShippingOptions = useCallback(
    (shippingOptions: InvoicingDetailState['shippingOptions']) => {
      dispatch({ type: 'setShippingOptions', payload: shippingOptions });
    },
    [dispatch]
  );

  const setOnSubmitAlertCount = useCallback(
    (onSubmitAlertCount: InvoicingDetailState['onSubmitAlertCount']) => {
      dispatch({ type: 'setOnSubmitAlertCount', payload: onSubmitAlertCount });
    },
    [dispatch]
  );

  const setCases = useCallback(
    (invoiceCases: InvoicingDetailState['invoiceCases']) => {
      dispatch({ type: 'setCases', payload: invoiceCases });
    },
    [dispatch]
  );

  const newCase = useCallback(
    (invoiceCase: InvoicingDetailState['invoiceCases'][0]) => {
      dispatch({ type: 'newCase', payload: invoiceCase });
    },
    [dispatch]
  );

  const updateCase = useCallback(
    (invoiceCase: Partial<CreatedOrder> & Pick<CreatedOrder, 'orderNumber'>) => {
      dispatch({ type: 'updateCase', payload: invoiceCase });
    },
    [dispatch]
  );

  const deleteCase = useCallback(
    (orderNumber: string) => {
      dispatch({ type: 'deleteCase', payload: orderNumber });
    },
    [dispatch]
  );

  const setInvoiceShippingInput = useCallback(
    (invoiceShippingInput: Partial<InvoicingDetailState['invoiceShippingInput']>) => {
      dispatch({ type: 'setInvoiceShippingInput', payload: invoiceShippingInput });
    },
    [dispatch]
  );

  const setInvoiceCoupons = useCallback(
    (coupons: DisplayDiscountItemType[]) => {
      dispatch({ type: 'setInvoiceAddDiscountInput', payload: { coupons } });
    },
    [dispatch]
  );

  const addNewInvoiceCoupon = useCallback(
    (coupon: Coupon, caseId: string) => {
      const isCouponExist = find(invoiceAddDiscountInput.coupons, { caseId: caseId });
      if (caseId && isCouponExist) {
        toast.error(`Coupon was already applied for the caseId - ${caseId}.`);
        return false;
      }
      const newCoupon = getDiscountCouponItem(coupon, caseId);
      dispatch({
        type: 'setInvoiceAddDiscountInput',
        payload: { coupons: [...invoiceAddDiscountInput.coupons, newCoupon] },
      });
      toast.success('Coupon applied successfully.');
      return true;
    },
    [dispatch, invoiceAddDiscountInput]
  );

  const deleteInvoiceCoupon = useCallback(
    (index: number) => {
      const filteredValues = invoiceAddDiscountInput.coupons.filter((_, i) => i !== index);
      dispatch({
        type: 'setInvoiceAddDiscountInput',
        payload: { coupons: filteredValues },
      });
    },
    [dispatch, invoiceAddDiscountInput]
  );

  const setInvoiceSpecialDiscounts = useCallback(
    (specialDiscounts: DisplayDiscountItemType[]) => {
      dispatch({ type: 'setInvoiceAddDiscountInput', payload: { specialDiscounts } });
    },
    [dispatch]
  );

  const addNewInvoiceSpecialDiscount = useCallback(
    (specialDiscount: DisplayDiscountItemType) => {
      const isSpecialDiscountExist = find(invoiceAddDiscountInput.specialDiscounts, { caseId: specialDiscount.caseId });
      if (isSpecialDiscountExist) {
        toast.error(`Special Discount already applied for the caseId - ${specialDiscount.caseId}.`);
        return false;
      }
      dispatch({
        type: 'setInvoiceAddDiscountInput',
        payload: { specialDiscounts: [...invoiceAddDiscountInput.specialDiscounts, specialDiscount] },
      });
      return true;
    },
    [dispatch, invoiceAddDiscountInput]
  );

  const deleteInvoiceSpecialDiscount = useCallback(
    (index: number) => {
      const filteredValues = invoiceAddDiscountInput.specialDiscounts.filter((_, i) => i !== index);
      dispatch({
        type: 'setInvoiceAddDiscountInput',
        payload: { specialDiscounts: filteredValues },
      });
    },
    [dispatch, invoiceAddDiscountInput]
  );

  const getSelectedShippingData = useCallback(() => {
    const filteredShippingOptions = shippingOptions.filter(option => {
      return (
        option.package === invoiceShippingInput.packageType &&
        option.carrier === invoiceShippingInput.carrier &&
        option.service === invoiceShippingInput.shippingService &&
        option.isSaturdayDelivery === invoiceShippingInput.isSaturdayDelivery
      );
    });

    let signatureRequiredOption: ShippingOption | undefined;
    let signatureNotRequiredOption: ShippingOption | undefined;

    // Find the signature required and not required options.
    filteredShippingOptions.forEach((option: ShippingOption) => {
      // Check for GSO if user did not enter a tracking number
      const includeGSOCheck = invoiceShippingInput.trackingNumber
        ? invoiceShippingInput.isSignatureRequired
        : option.carrier === ShippingCarrier.GSO || invoiceShippingInput.isSignatureRequired;

      const isOptionSignatureRequired = option.requiresSignature === includeGSOCheck;

      /**
       * If the option requires a signature, and there is no signature required option, set the option as the signature required option.
       * else If the option does not require a signature, and there is no signature not required option, set the option as the signature not required option.
       */
      if (isOptionSignatureRequired && !signatureRequiredOption) {
        signatureRequiredOption = option;
      } else if (!isOptionSignatureRequired && !signatureNotRequiredOption) {
        signatureNotRequiredOption = option;
      }
    });

    const isSignatureRequiredPreference = invoiceShippingInput.isSignatureRequiredPreference !== null;
    // In LMS1-8105, we identified that, if there is no signature required option, and a signature required option exists, the signature required option should be selected.
    const shouldSignatureRequiredOptionBeSelected = isSignatureRequiredPreference || !signatureNotRequiredOption;

    const selectedShippingOption =
      !!signatureRequiredOption && shouldSignatureRequiredOptionBeSelected
        ? signatureRequiredOption
        : signatureNotRequiredOption;

    // Find the shipping charge that matches the selected shipping service.
    let outboundShippingCharge = getDefaultCharge();
    const outboundShippingChargeCheck = selectedShippingOption?.charges?.find(
      charge => charge.service === invoiceShippingInput.shippingService
    );

    if (outboundShippingChargeCheck) {
      outboundShippingCharge = outboundShippingChargeCheck;
    }

    // Find possible COD Fee
    const codFee = shippingOptions
      .find(option => option.rateId === selectedShippingOption?.rateId)
      ?.charges?.find(charge => charge.service === 'COD Fee');

    let signatureRequiredCost = 0;

    // Find the charge matching the billing account's Currency Code. Default to first currency in the array if no matching one is found.
    const outboundShippingChargeCost =
      outboundShippingCharge?.currencies.find(currency => {
        return currency.currencyCode === account?.currencyCode;
      })?.price || 0;

    let signatureRequiredCharge: Charge | undefined;
    // Look for signature required charge and add to cost if signature required is checked.
    if (invoiceShippingInput.isSignatureRequired) {
      signatureRequiredCharge = selectedShippingOption?.charges?.find(charge => {
        return charge.service === ShippingService.SIGNATURE_REQUIRED;
      });

      // Find charge matching the billing account's currency code.
      signatureRequiredCost =
        signatureRequiredCharge?.currencies.find(currency => {
          return currency.currencyCode === account?.currencyCode;
        })?.price || 0;
    }

    let saturdayDeliveryCharge: Charge | undefined;
    let saturdayDeliveryCost = 0;
    // Look for saturday delivery charge and add to cost if saturday delivery is checked.
    if (invoiceShippingInput.isSaturdayDelivery) {
      saturdayDeliveryCharge = selectedShippingOption?.charges?.find(charge => {
        return charge.service === ShippingService.SATURDAY_DELIVERY;
      });

      // Find charge matching the billing account's currency code.
      saturdayDeliveryCost =
        saturdayDeliveryCharge?.currencies.find(currency => {
          return currency.currencyCode === account?.currencyCode;
        })?.price || 0;
    }

    // Get all applied discounts from all the invoice cases to find if there is an entire case discount applied.
    const appliedDiscounts = invoiceCases.flatMap(invoiceCase => invoiceCase.appliedDiscounts || []);
    const hasEntireCaseDiscount = convertDeductionToItems(appliedDiscounts).some(
      discount => discount.orderItemId === DISCOUNT_TYPE_LABELS.ENTIRE_CASE
    );

    const outboundDiscountPercentage = getOutboundDiscountPercentage(appliedDiscounts);
    const outboundRate = outboundShippingChargeCost + signatureRequiredCost + saturdayDeliveryCost;

    return {
      selectedShippingOption,
      outboundShippingCharge,
      codFee,
      outboundShippingChargeCost: outboundShippingChargeCost,
      signatureRequiredCharge,
      signatureRequiredCost: signatureRequiredCost,
      outboundRate: outboundRate,
      saturdayDeliveryCharge,
      saturdayDeliveryCost: saturdayDeliveryCost,
      // If there is an entire case discount applied, then set the outboundDiscountPercentage to 100 percentage
      outboundDiscountPercentage: hasEntireCaseDiscount ? 100 : outboundDiscountPercentage,
    };
  }, [
    account?.currencyCode,
    invoiceCases,
    invoiceShippingInput.carrier,
    invoiceShippingInput.isSaturdayDelivery,
    invoiceShippingInput.isSignatureRequired,
    invoiceShippingInput.isSignatureRequiredPreference,
    invoiceShippingInput.packageType,
    invoiceShippingInput.shippingService,
    invoiceShippingInput.trackingNumber,
    shippingOptions,
  ]);

  const doNotShipOnSaturday = useCallback(
    () => invoiceShippingInput.doNotShip.find(entry => entry.day === 'Saturday'),
    [invoiceShippingInput.doNotShip]
  );

  const setProductReturnRequirements = useCallback(
    (productReturnRequirements: InvoicingDetailState['productReturnRequirements']) => {
      dispatch({ type: 'setProductReturnRequirements', payload: productReturnRequirements });
    },
    [dispatch]
  );

  /**
   * The case that can be charged shipping or the first case if there are no cases that can be charged shipping.
   */
  const outboundApplicableCase = useMemo(() => {
    if (!invoiceCases.length) return;
    return getOutboundApplicableCase(invoiceCases, productReturnRequirements) || invoiceCases[0];
  }, [invoiceCases, productReturnRequirements]);

  const setCodAmount = useCallback(
    (codAmountToSet: number) => {
      dispatch({ type: 'setCodAmount', payload: codAmountToSet });
    },
    [dispatch]
  );

  return {
    ...context,
    account,
    shipping,
    shippingOptions,
    onSubmitAlertCount,
    invoiceCases,
    invoiceShippingInput,
    invoiceAddDiscountInput,
    outboundApplicableCase,
    getSelectedShippingData,
    setAccount,
    setShipping,
    setShippingOptions,
    setOnSubmitAlertCount,
    setCases,
    newCase,
    updateCase,
    deleteCase,
    setInvoiceShippingInput,
    setInvoiceCoupons,
    addNewInvoiceCoupon,
    deleteInvoiceCoupon,
    setInvoiceSpecialDiscounts,
    addNewInvoiceSpecialDiscount,
    deleteInvoiceSpecialDiscount,
    doNotShipOnSaturday,
    setProductReturnRequirements,
    codAmount,
    setCodAmount,
  };
};
