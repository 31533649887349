import { ToolTip } from 'components/common/ToolTip/ToolTip';
import { ColorCheckBox } from 'components/invoicing/ShippingDetail/ColorCheckBox/ColorCheckBox';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useEffect } from 'react';
import { getCheckBoxVariant } from 'shared/helpers/invoice/invoice.helper';

/**
 * Props for the RequireSignatureCheckbox component.
 */
interface RequireSignatureCheckboxProps {
  /**
   * A boolean value indicating whether the require signature checkbox should be disabled.
   */
  disabled?: boolean;
}

/**
 * Require signature checkbox component.
 * Allows users to toggle the option for requiring signature on delivery.
 * If external tracking number is available, the option will be disabled by default.
 * @param disabled - A boolean value indicating whether the require signature checkbox should be disabled.
 * @returns JSX element representing the require signature checkbox.
 */
export const RequireSignatureCheckbox: React.FC<RequireSignatureCheckboxProps> = ({ disabled }) => {
  const { invoiceShippingInput, setInvoiceShippingInput } = useInvoicingDetail();
  const { trackingNumber, trackingNumberBundledOrder, isSignatureRequired, isSignatureRequiredPreference } =
    invoiceShippingInput;

  /**
   * Handles the change event for the require signature checkbox.
   * Updates the isSignatureRequired property in the invoiceShippingInput state.
   * @param checked - Boolean value indicating whether the checkbox is checked.
   */
  const onChange = (checked: boolean) => {
    setInvoiceShippingInput({ isSignatureRequired: checked });
  };

  const variant = getCheckBoxVariant(
    !!isSignatureRequiredPreference,
    isSignatureRequiredPreference !== isSignatureRequired
  );

  /**
   * After effect to reset the checkbox to false if disabled
   * if the disable prop is already true and user switch the carrier and
   * shipping method selected with disabled true again,
   * then after effect won't get triggered if we check just the disable prop
   * That is why we need to check any change in invoiceShippingInput.shippingService too
   * if external trackingNumber is available then this option will be disabled by default, but we need to render-
   * the isSignatureRequired as per the added tracking number's signature required property as per the AC-
   * So here we have to validate invoiceShippingInput.trackingNumber too.
   */
  useEffect(() => {
    /**
     * If tracking number is available, then we don't need to update the isSignatureRequired property.
     */
    if (trackingNumber) return;

    if (disabled) {
      setInvoiceShippingInput({ isSignatureRequired: false });
    } else if (isSignatureRequiredPreference) {
      // In LMS1-7597, we established that the signature required preference should only be applied
      // if there is no tracking number applied.
      setInvoiceShippingInput({ isSignatureRequired: true });
    }
  }, [disabled, isSignatureRequiredPreference, setInvoiceShippingInput, trackingNumber]);

  return (
    <div className="group relative">
      <ColorCheckBox
        label="Require signature"
        checked={isSignatureRequired}
        onChange={onChange}
        variant={variant}
        disabled={!!trackingNumberBundledOrder || !!disabled}
        isClickable
        labelClassName={disabled ? 'opacity-30' : undefined}
      />
      {disabled && <ToolTip text="Require signature option is not available for your current shipping selection" />}{' '}
    </div>
  );
};
